export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/administracion",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Usuarios",
        url: "/listado-usuarios",
        icon: "mdi-account-multiple",
        permissions: ["ADMIN_SYSTEM_USERS"],
      },
      {
        name: "Grupos de usuario",
        url: "/administracion-grupos",
        icon: "mdi-account-network",
        permissions: ["ADMIN_WORKGROUPS"],
      },
      {
        name: "Tareas",
        url: "/administracion-tareas",
        icon: "mdi-playlist-check",
        permissions: ["ASSIGN_REASSIGN_TASK"],
      },
      {
        name: "Grupos de permisos",
        url: "/security_groups",
        icon: "mdi-account-key-outline",
        permissions: ["ADMIN_GRUPOS_PERMISOS"],
      },
      {
        name: "Catálogo de Productos",
        url: "/productos",
        icon: "mdi-package-variant",
        permissions: ["LISTA_PRODUCTOS"],
      },
      {
        name: "Categorías de productos",
        url: "/categoria-producto",
        icon: "mdi-format-list-bulleted",
        permissions: ["LISTA_CATEGORIA_PROD"],
      },
      {
        name: "Promociones",
        url: "/promociones",
        icon: "mdi-sale",
        permissions: ["PROMOCIONES_ADMIN"],
      },
      {
        name: "Acuerdos",
        url: "/acuerdos",
        icon: "mdi-thumb-up-outline",
        permissions: ["ACUERDOS_ADMIN"],
      },
      {
        name: "Reglas de precio",
        url: "/regla-precio",
        icon: "mdi-cash-100",
        permissions: ["PRICE_RULES_ADMIN"],
      },
      {
        name: "Precios cuatrimestre",
        url: "/precios_cuatrimestre",
        icon: "mdi-file-export",
        permissions: ["PRECIOS_CUATRIMESTRE"],
      },
      {
        name: "Closeup",
        url: "/reporte_closeup",
        icon: "mdi-file-export",
        permissions: ["REP_CLOSEUP"],
      },
      {
        name: "Reservas de psicotropicos",
        url: "/reservas_psicotropicos",
        icon: "mdi-file-export",
        permissions: ["REP_RESERVAS_PSICO"],
      },

      {
        name: "Movimientos de psicotrópicos",
        url: "/ventas_psicotropicos",
        icon: "mdi-file-export",
        permissions: ["REP_VENTAS_PSICO"],
      },

      {
        name: "Reporte de promociones",
        url: "/rep_promo_compras",
        icon: "mdi-file-export",
        permissions: ["REP_PROMO_COMPRAS"],
      },
      {
        name: "Tiendas",
        url: "/administracion-tiendas",
        icon: "mdi-store",
        permissions: ["ADMIN_TIENDAS"],
      },
    ],
  },
  getters: {
    menuAdmin(state) {
      var menuAcceso = [];
      for (let i = 0; i < state.menu.length; i++) {
        if (state.menu[i].name == "Inicio") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Usuarios") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Grupos de usuario") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Tareas") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Grupos de permisos") {
          menuAcceso.push(state.menu[i]);
        }
      }

      return menuAcceso;
    },
  },
};
