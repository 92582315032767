<template>
    <div>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  
      <v-card>
        <v-toolbar flat height="auto" color="white" class="mt-3">
          <v-toolbar-title>Facturas de promociones</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>
          
          <v-col cols="4">
          <v-menu v-model="menu" :close-on-content-click="false" >
            <template v-slot:activator="{ on, attrs }">              
              <v-text-field
              class="mt-5"
                v-model="rangoFechas"
                label="Fechas"
                persistent-hint
                readonly
                append-icon="mdi-calendar-multiple"
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                
              ></v-text-field>
            
            </template>
            <v-date-picker
              range
              v-model="searchFechas"
              no-title
              @change="menu = false; cargarReporte()"
              locale="es-es"
            >
            </v-date-picker>
            
          </v-menu>
        </v-col>
        <v-col>
          
          <v-autocomplete            
            :items="productos"
            label="Productos"            
            clearable
            item-text="internal_name"
            item-value="product_id"
            item-key="product_id"
            v-model="productosSel"
            append-icon="mdi-magnify"
            class="mt-4 mx-4"            
            multiple
            dense
            small-chips
            outlined
            deletable-chips
            @change="cargarReporte()"
            
          >
          
        </v-autocomplete >
        </v-col>
        

        </v-toolbar>
        <v-toolbar flat color="white">
          <v-btn
            color="primary"
            small
            class="ma-2 white--text"
            @click="exportar()"
          >
            <v-icon left>mdi-microsoft-excel</v-icon>
            Exportar
          </v-btn>

          <v-btn
            color="primary"
            small
            class="ma-2 white--text"
            @click="descargarPdf()"
          >
            <v-icon left>mdi-zip-box-outline</v-icon>
            Descargar Facturas
          </v-btn>

          <v-col>
            <v-btn color="blue" dark small @click="abrirDialogProductos"><v-icon left small>mdi-package-variant-closed-plus</v-icon>Productos</v-btn>
          </v-col>
        </v-toolbar>
  
        <v-data-table
          :headers="headersLista"
          :items="lista"
          :search="search"
          :loading="loadingTable"
          :disable-sort="$vuetify.breakpoint.name == 'xs'"
          :items-per-page="15"
          :options.sync="options"
          loading-text="Cargando datos..."
          no-results-text="No se han encontrado datos"
          no-data-text="Sin datos"
          class="elevation-1"
          dense
        >
        </v-data-table>
      </v-card>

      <!-- dialog productos -->
      <v-dialog v-model="dialogProductos" width="900" persistent scrollable>      
        <v-card>
          <v-toolbar height="40"  color="blue" flat dark fixed>
            <v-toolbar-title><v-icon left >mdi-package-variant-closed-plus</v-icon>PRODUCTOS</v-toolbar-title>            
            <v-spacer></v-spacer>
            <v-btn class="white--text" text small @click="dialogProductos=!dialogProductos"><v-icon left>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="mt-4">
              <v-toolbar flat> 
                <v-col>
                  <v-autocomplete            
                    :items="catalogo"
                    label="Catalogo"                    
                    clearable
                    item-text="internal_name"
                    item-value="product_id"
                    item-key="product_id"
                    v-model="catalogoId"
                    append-icon="mdi-magnify"
                    class="mt-4 mx-4"                    
                    flat
                  ></v-autocomplete >
                </v-col>
                <v-col><v-btn @click="agregarProductoPromo()" small color="green" dark ><v-icon>mdi-plus</v-icon></v-btn></v-col>
                
              <v-col><v-text-field label="Buscar" v-model="buscarProd" class="mt-5"></v-text-field></v-col>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col>
                
                  <v-data-table :search="buscarProd" :items="productos" :headers="headerProductos">
                    <template v-slot:item.opciones="{item}">                      
                      <v-btn dark icon small color="red" @click="borrarProductoPromo(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                    </template>

                  </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>        
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  
  export default {
    name: "ReportePromocionesCompras",
    
    data: () => ({
      headersLista: [
       
        { text: "Fecha. Fa", value: "fecha" },
        { text: "Num. Fa", value: "invoice_number" },        
        { text: "Nombre de producto", value: "detalle_producto" },
        { text: "Establecimiento", value: "nombre_establecimiento" },
        { text: "Tipo factura", value: "tipo_factura" },
        { text: "Cantidad", value: "cantidad" },
        
      ],
      search: "",
      buscarProd: "",
      productos: [],
      catalogo: [],
      productosSel: [],
      lista: [],
      overlay: false,
      options: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: true,
      searchFechas: [],
      searchEstab: "",
      menu: false,
      dialogProductos: false,
      catalogoId: "",
      filters: [
        {
          cols: 6,
          class: "py-0 py-md-2 col-md-2 pr-0 pr-sm-2",
          v_model: [],
          label: "Fecha",
          type: "input_date",
          clearable: true,
        },
        {
          cols: 6,
          class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
          v_model: "",
          label: "Bodega",
          type: "input_select",
          text: "tipoNombre",
          value: "tipoId",
          clearable: true,
          multiple: true,
          items: [],
        },
      ],
      headerProductos : [
        { text: "Opciones", value: "opciones" },
        { text: "Codigo", value: "product_id" },
        { text: "Producto", value: "internal_name" },        
        
        
      ]
    }),
    computed: {
      ...mapState("master", ["loadingTable", "user", "tenantId"]),
  
      ...mapGetters("access", ["btnAbrirCaja"]),
  
      rangoFechas() {
        return this.searchFechas.join(" ~ ");
      },
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setMenu",
        "setTitleToolbar",
        "setLoadingTable",
      ]),
  
      ...mapActions("master", ["requestApi", "alertNotification"]),
  
      exportar() {
        this.overlay = true;
  
        if (this.searchFechas[0] == null) {
          this.overlay = false;
          //alert('Seleccione una fecha');
          this.alertNotification({
            param: {
              html: "Seleccione una fecha.",
              timer: 10000,
              title: "Error",
              icon: "mdi-cancel",
              confirmButtonColor: "red",
            },
          });
  
          return false;
        }         
  
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "exportarPromociones",
            desde: this.searchFechas[0],
            hasta: this.searchFechas[1],
            productosSel : this.productosSel
          },
        })
          .then((res) => {            
            var a = document.createElement("a");
            a.href =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              res.data.detail.datos;
            a.download = res.data.detail.archivo;
            a.click();
            this.overlay = false;
          })
          .then(() => {
            this.overlay = false;
          }).catch(()=>{
            this.overlay = false;
          });
      },
  
      descargarPdf() {
        this.overlay = true;
  
        if (this.searchFechas[0] == null) {
          this.overlay = false;
          
          this.alertNotification({
            param: {
              html: "Seleccione una fecha.",
              timer: 10000,
              title: "Error",
              icon: "mdi-cancel",
              confirmButtonColor: "red",
            },
          });
  
          return false;
        }         
  
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "descargarFacturasPromociones",
            desde: this.searchFechas[0],
            hasta: this.searchFechas[1],    
            productosSel : this.productosSel        
          },
        })
          .then((res) => {
            //console.log(res.data);
  
            var a = document.createElement("a");
            a.href =
              "data:application/zip;base64," +
              res.data.detail.datos;
            a.download = res.data.detail.archivo;
            a.click();
            this.overlay = false;
          })
          .then(() => {
            this.overlay = false;
          }).catch(()=>{
            this.overlay = false;
          });
      },      

      cargarReporte() {      
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "promociones",
            desde: this.searchFechas[0],
            hasta: this.searchFechas[1],
            productosSel : this.productosSel
          },
        })
          .then((res) => {
            this.lista = res.data.detail.lista;            
          })
          .catch(() => {});
      },
  
      abrirDialogProductos() {
        this.dialogProductos=true
      } ,

      borrarProductoPromo(item) {
        console.log(item.product_id)
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "borrarProductoPromo",            
            productosSel: item.product_id
          },
        })
          .then((res) => {
            this.cargarFormData()
          })
          .catch(() => {});
      },

      agregarProductoPromo(item) {
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "agregarProductoPromo",            
            productId : this.catalogoId
          },
        })
          .then((res) => {
            this.cargarFormData()
          })
          .catch(() => {});
      }
      , cargarFormData() {
        this.setUrl("reportes");
        this.requestApi({
          method: "POST",
          data: {
            accion: "promocionesFormData",
            productoId : this.productoId
          },
        })
          .then((res) => {            
            this.productos = res.data.detail.productos;
            this.catalogo = res.data.detail.catalogo;
          })
          .catch(() => {});
        
      }

    },
  
    mounted() {      
      this.cargarReporte();
      this.cargarFormData();
      this.setTitleToolbar("REPORTE DE PROMOCIONES");
    },
  
    watch: {},
  };
  </script>
  