<template>
    <div class="text-xs-center">
      <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      
      <v-toolbar flat>
        <v-icon left>mdi-bullhorn-outline</v-icon>
        <v-toolbar-title>ANUNCIOS </v-toolbar-title>        
        
        <v-btn :to="`crm_campania`" outlined small class="mx-2" color="#1f1f1f" dark><v-icon small>mdi-arrow-left</v-icon>Campañas</v-btn>
        
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="agregarAnuncio()" color="blue" small dark
          ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >
        <v-col col="2">
          <v-text-field class="mt-4" label="buscar" v-model="buscarAnuncio"></v-text-field>
        </v-col>
        <v-col col="2">
          <v-checkbox class="mt-4" v-model="incluirInactivas" @change="cargarListaAnuncios()" label="Incluir inactivas"></v-checkbox>
        </v-col>
        
      </v-toolbar>
  
      <v-data-table
        :search="buscarAnuncio"
        :headers="headersAnuncios"
        :items-per-page="itemsPerPage"
        :items="anuncios"
        class="elevation-1"
        small
        hide-default-footer
      >
        <template v-slot:item.mk_anuncio_id="{ item }">
          
          <v-btn
            text
            small
            color="green"
            @click="editarAnuncio(item)"
            ><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="borrarAnuncio(item.mk_anuncio_id)" text small>
            <v-icon color="red" small>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
  
      
      <!-- DIALOGO ANUNCIO-->
      <v-dialog v-model="dialogAnuncio" persistent max-width="800" min-width="600">
        <v-form ref="formAnuncio">
          <v-card>
            <v-toolbar dark color="blue" height="40" flat>
              Anuncio
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogAnuncio = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-autocomplete
                  :items="anuncios"
                  v-model="datosAnuncio.marketing_campaign_id"
                  item-value="marketing_campaign_id"
                  item-text="campaign_name"
                >

                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  v-model="datosAnuncio.descripcion"
                  label="Nombre del anuncio"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-textarea
                rows="10"
                  v-model="datosAnuncio.texto"
                  label="Texto del anuncio"
                  :rules="requiredRule"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-autocomplete                  
                  v-model="datosAnuncio.tipo_servicio_id"
                  label="Tipo de servicio"
                  :rules="requiredRule"
                  :items="tiposServicio"
                  item-text="nombre"
                  item-value="servicio_crm_id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-toolbar>
              <v-btn color="primary" small @click="guardarAnuncio">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn>
              <v-divider vertical class="px-2" inset></v-divider>
              <v-btn text small @click="dialogAnuncio = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import Vue from 'vue'
  
  export default {
    name: "Anunciosmk",
    data: () => ({
      show: false,
  
      requiredRule: [(v) => !!v || "El campo es requerido"],
      options: {},
      setfilters: [],
      selected: [],
      users: [],
      totalItems: 1,
      currentPage: 1,
      itemsPerPage: 10,
      pageCount: 1,
      overlay: false,
      buscarAnuncio: "",
      incluirInactivas: false,
  
      required: [(v) => !!v || "El campo es requerido"],
  
      
      campanias: [],
      anuncios: [],
      crearCampania: false,
      headersAnuncios: [
        { text: "ID", align: "start", value: "mk_anuncio_id" },        
        { text: "Anuncio", align: "start", value: "descripcion" },
        { text: "Servicio", align: "start", value: "servicio"},
        { text: "Campaña", align: "start", value: "campaign_name"}
      ],
      dialogAnuncio: false,
      datosAnuncio: {
          mk_anuncio_id: "",
          marketing_campaign_id: "",
          description: "",
          texto: "",
          tipo_servicio_id: "",
          tipo_servicio: "",
      },
      tiposServicio:[]
    }),
    computed: {
      ...mapState("master", ["loadingBtn", "companies"]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
      ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
      ]),
      ...mapActions("master", ["requestApi", "alertNotification"]),
      ...mapActions("access", []),
  
      cargarListaAnuncios() {
        this.setLoadingTable(true);
        this.overlay = true;
        this.setUrl("anuncios-marketing");
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,          
            page_size: this.itemsPerPage,
            incluir_inactivas: this.incluirInactivas
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.anuncios = res.data._embedded.anuncios;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });
      },
      cargarDatosFormAnuncios() {
        this.setLoadingTable(true);
        this.setUrl("anuncios-marketing");
        this.requestApi({
          method: "POST",
          data: {
            accion: "cargarDatosFormAnuncios"
          },
        })
          .then((res) => {
            //console.log(res.data.detail);            
            this.campanias = res.data.detail.campanias;
            this.tiposServicio = res.data.detail.tipos_servicio;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      }
       
      ,
      agregarAnuncio() {
        this.dialogAnuncio = true;
        this.datosAnuncio = {
          mk_anuncio_id : ""
        };
        
      },

      
  
      guardarAnuncio() {
        if (!this.$refs.formAnuncio.validate()) {
          return false;
        }
        this.setLoadingTable(true);
        this.setUrl("campania-marketing");
        this.requestApi({
          method: "POST",
          data: {
            accion: this.datosAnuncio.mk_anuncio_id == "" ? "crearAnuncioCampania" : "modificarAnuncioCampania",
            datosAnuncio: this.datosAnuncio,
          },
        })
          .then((res) => {
            //console.log(res.data.detail);
  
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
            //this.cargarDatosAnuncio(this.datosCampania.marketing_campaign_id);
            this.cargarListaAnuncios()
            this.dialogAnuncio = false;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
      
      borrarAnuncio(mkAnuncioId) {
        Vue.swal({
                  html: "Está seguro de eliminar este anuncio ?",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Aceptar',
                  cancelButtonText: 'Cerrar',
                  ...this.paramAlertQuestion
              }).then( result => {
                  
                  if (result.isConfirmed) {
                  this.setLoadingTable(true);
                  this.setUrl("campania-marketing");
                  this.requestApi({
                    method: "POST",
                    data: {
                      accion: "borrarAnuncioCampania",
                      mkAnuncioId: mkAnuncioId,
                    },
                  })
                    .then((res) => {  
                      this.alertNotification({
                        param: {
                          html: res.data.detail.msg,
                        },
                      });
                      this.cargarListaAnuncios()
                    })
                    .then(() => {
                      this.setLoadingTable(false);
                    });
                  }
                });
                
      },
      editarAnuncio(item) {
        this.dialogAnuncio = true;      
        
        this.cargarAnuncio(item.mk_anuncio_id)
      },
      cargarAnuncio(mkAnuncioId) {
        this.setUrl("campania-marketing");
        this.requestApi({
          method: "POST",
          data: {
            accion: "cargarDatosAnuncio",
            mkAnuncioId: mkAnuncioId
          },
        })
          .then((res) => {
            this.datosAnuncio = res.data.detail.anuncio
            //this.datos.anuncio_nombre = res.data.detail.anuncio.descripcion;
            //this.anuncio_texto = res.data.detail.anuncio.texto;
            //this.anuncio_tipo_servicio = res.data.detail.anuncio.tipo_servicio_id;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
  
      },
  
      handlePageChange(value) {
        this.cargarListaAnuncios();
      },
    },
    mounted() {
      this.cargarDatosFormAnuncios();
      this.cargarListaAnuncios();
      this.setTitleToolbar("ANUNCIOS DE MARKETING");
    },
  };
  </script>
  