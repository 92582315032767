<template>
    <div>
      <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="pa-4">

        <v-toolbar flat color="white">
          <v-toolbar-title>Asiento contable :: {{datosAsiento.acctg_trans_id}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical> </v-divider>          
          
          <v-btn v-if="datosAsiento.is_posted=='N'" @click="guardarAsiento()" class="mx-4" dark small color="blue"><v-icon left small>mdi-pencil</v-icon>Guardar</v-btn>
          <v-btn v-if="datosAsiento.is_posted=='N'" @click="procesarAsiento" class="mx-4" dark small color="blue"><v-icon left small>mdi-cogs</v-icon>Procesar</v-btn>

        </v-toolbar>       
     
        <v-row class="my-0 py-0">
            <v-col cols="6">
                <v-text-field :readonly="true" label="Num. transacción" v-model="datosAsiento.acctg_trans_id" />
            </v-col>
            <v-col cols="6">
                <v-autocomplete  
                    :readonly="lectura" 
                    label="Tipo de transacción" 
                    :items="tiposAsiento" 
                    item-value="acctg_trans_type_id" 
                    item-text="description" 
                    v-model="datosAsiento.acctg_trans_type_id" ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-0 py-0">
            <v-col cols="6" class="my-0 py-0">
                <v-text-field :readonly="lectura" type="date" label="Fecha. transacción" v-model="datosAsiento.transaction_date" />                
            </v-col>
            
            <!-- <v-col cols="6" class="my-0 py-0">
                <v-text-field  readonly  label="Fecha de registro" v-model="datosAsiento.posted_date" />                
            </v-col> -->
        
        </v-row>
        <!-- <v-row class="my-0 py-0">
            <v-col cols="6" class="my-0 py-0">
                <v-text-field :readonly="lectura" label="Procesado" v-model="datosAsiento.is_posted" />                
            </v-col>
            
            <v-col cols="6" class="my-0 py-0">
                <v-text-field :readonly="lectura" label="Creado por el usuario" v-model="datosAsiento.created_by_user_login" />
            </v-col>
        
        </v-row> -->

        <v-row class="my-0 py-0">
            <v-col cols="12" class="my-0 py-0">
                <v-text-field :readonly="lectura" label="Descripción" v-model="datosAsiento.description" />                
            </v-col>                                
        </v-row>

        <v-row class="my-0 py-0">
            <v-col cols="6" class="my-0 py-0">
                <v-text-field :readonly="lectura" label="Participante" v-model="datosAsiento.party_name" />                
            </v-col>
            <v-col cols="6" class="my-0 py-0">
                <v-text-field :readonly="lectura" label="Rol del participante" v-model="datosAsiento.role_name" />                
            </v-col>
        </v-row>

        <v-row class="my-0 py-0">
            <v-col cols="6" v-if="datosAsiento.payment_id != null && datosAsiento.acctg_trans_type_id != 'RETENCION_EMITIDA'">
                 
                <v-text-field readonly label="Pago" v-model="datosAsiento.payment_id" >
                <template slot="append" >
                    <v-btn v-if="datosAsiento.acctg_trans_type_id=='INCOMING_PAYMENT'" target="_blank" icon small :to="`/ver-pago/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    <v-btn v-if="datosAsiento.acctg_trans_type_id=='OUTGOING_PAYMENT'" target="_blank" icon small :to="`/ver-pago-emitido/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                </template>
                </v-text-field> 
            </v-col>

            <v-col cols="6" v-if="datosAsiento.invoice_id != null">
                
                <v-text-field readonly label="Factura" v-model="datosAsiento.codigo_establecimiento + '-' + datosAsiento.codigo_punto_emision + '-' + datosAsiento.invoice_number" >
                <template slot="append" >
                    <v-btn v-if="datosAsiento.parent_invoice_type_id=='SALES_INVOICE'" target="_blank" icon small :to="`/factura-venta/${datosAsiento.invoice_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    <v-btn v-if="datosAsiento.parent_invoice_type_id=='PURCHASE_INVOICE'" target="_blank" icon small :to="`/ver-factura-compra/${datosAsiento.invoice_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                </template>
                </v-text-field>
            </v-col>

            <v-col cols="6" v-if="datosAsiento.fin_account_trans_id != null">
                
                <v-text-field readonly label="Caja" v-model="datosAsiento.caja_id" >
                <template slot="append" >
                    <v-btn  target="_blank" icon small :to="`/ver-caja/${datosAsiento.caja_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>                    
                </template>
                </v-text-field>
            </v-col>

            <v-col cols="6">
                <v-text-field label="Transacción" v-model="datosAsiento.fin_account_trans_id" />                
            </v-col>

            <v-col cols="6" v-if="datosAsiento.payment_id != null && datosAsiento.acctg_trans_type_id =='RETENCION_EMITIDA'">
                
                <v-text-field label="Retención" v-model="datosAsiento.payment_id" >
                    <template slot="append" >
                    <v-btn target="_blank" icon small :to="`/retencion-emitida/${datosAsiento.payment_id}`"><v-icon color="green">mdi-eye</v-icon></v-btn>
                    
                </template>
                </v-text-field>
            </v-col>
        </v-row>            
        <v-toolbar color="#cecece" rounded height="54" >
        <v-row>
            <v-col class="ml-4 " cols="4">                
                <v-btn v-if="datosAsiento.is_posted=='N'" @click="nuevoApunte" fab small title="agregar apunte" color="green" dark ><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
            <v-col cols="2" class="mt-2">
                TOTAL DEBE:  {{totalDebito}}  
            </v-col>
            <v-col cols="2" class="mt-2">
                TOTAL CREDITO:  {{totalCredito}}  
            </v-col>

            <v-col cols="2" class="mt-2">
                DIFERENCIA:  {{(totalDebito - totalCredito).toFixed(2)}}  
            </v-col>
        </v-row>
        </v-toolbar>
        <v-data-table :items-per-page="itemsPerPage" :items="listaAte" :headers="headersAte"  >
        <template v-slot:item.opcion="{item}">
            <v-btn v-if="datosAsiento.is_posted=='N'" icon small color="green" @click="editarApunte(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn v-if="datosAsiento.is_posted=='N'" icon small color="red" @click="borrarApunte(item)"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        </v-data-table>
        
        
    </v-card>

    <!-- dialogo apunte contable -->
    <v-dialog v-model="dialogoApunte" width="500">
        <v-card>
            <v-toolbar height="40" flat dark color="blue">
                <v-toolbar-title>Apunte contable</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn  @click="dialogoApunte=!dialogoApunte" small icon dark><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-form ref="formApunte">
            <v-card-text>
                <v-row class="my-2 mx-2">
                    {{datosApunte.acctg_trans_entry_seq_id}}
                    <v-col cols="12" class="ma-0">
                        <!-- <v-text-field v-model="datosApunte.debit_credit_flag" label="Débito / Crédito" ></v-text-field> -->
                        <v-select label="Débito / Crédito" 
                            :rules="requiredRule"
                                    v-model="datosApunte.debit_credit_flag"                                 
                                    :items="debitoCredito"
                                    item-text="texto" item-value="valor" 
                                    ></v-select>
                    </v-col>
                    <v-col cols="12" class="ma-0">
                            <v-autocomplete  
                                :rules="requiredRule"
                                :readonly="lectura" 
                                label="Cuenta contable " 
                                :items="cuentas" 
                                item-value="gl_account_id" 
                                item-text="nombre_cuenta" 
                                v-model="datosApunte.gl_account_id" ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="my-0">
                            <v-text-field :rules="requiredRule" :readonly="lectura" label="Monto" v-model="datosApunte.amount" />                
                        </v-col>        

                        <v-col cols="12" class="my-0">
                            <v-text-field  :readonly="lectura" 
                                label="Observaciones" v-model="datosApunte.description" />                
                        </v-col>        
                    
                    </v-row>
                </v-card-text>
            </v-form>
            <v-toolbar>
                <v-col><v-btn @click="guardarApunte()" small color="green" dark><v-icon left>mdi-content-save</v-icon>Guardar</v-btn></v-col>
                <v-col><v-btn small @click="dialogoApunte=!dialogoApunte" ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn></v-col>
            </v-toolbar>
        </v-card>
    </v-dialog>
</div>
</template>


<script>

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Vue from 'vue'

export default {
    name: 'AsientoContableVista',
    props: {
        

    },
    components: {

    },
    data: () => ({
        cajaNum: "",
        cajaFecha: "",
        cajaEstado: "",
        cajaUsuario: "",
        headersAte: [
            { text: "Opcion", value:"opcion"},
            { text: "Transaccion", value: "acctg_trans_id"},
			{ text: "Seq", value: "acctg_trans_entry_seq_id" },						
			{ text: "Cuenta", value: "cuenta_nombre" },			 
            { text: "Descripcion", value: "description" },			 
			{ text: "Monto", value: "amount" },
			{ text: "D/C", value: "debit_credit_flag" },
			
		],
        requiredRule: [(v) => !!v || "El campo es requerido"],
        listaAte: [],
        options: {},
        overlay: false,
        search: "",
        uid: "",
        datosAsiento: {},
        lectura: false,

        pageCount: 1,
        currentPage: 1,
        itemsPerPage: 20,
        tiposAsiento: [],
        cuentas: [],
        dialogoApunte:false,
        datosApunte: {},
        debitoCredito: [{"valor" : "D", "texto": "Débito"},{"valor" : 'C', "texto" : "Crédito"}],


    }),
    computed: {
        ...mapState('master', ['loadingTable']),
        ...mapState('contabilidad', ['menu']),
        ...mapState('pos', ['menuPos']),
        ...mapGetters('access', ['conciliarCaja']),
        
        totalDebito () {
            let total = 0;            

            for(let i=0; i < this.listaAte.length ; i++) {
                if(this.listaAte[i].debit_credit_flag=='D') {
                    total += parseFloat(this.listaAte[i].amount)
                }
                
            }
            return total.toFixed(2);
        },
        totalCredito() {
            
            let total = 0;

            for(let i=0; i < this.listaAte.length ; i++) {
                
                if(this.listaAte[i].debit_credit_flag=='C') {
                    total += parseFloat(this.listaAte[i].amount)
                }
            }
            return total.toFixed(2)

        }
    }
    , methods: {
        ...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable']),

        ...mapActions('master', ['requestApi']),

        cargarDatos() {
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    accion: "datosAsientoForm"                         
                }
            }).then(res => {                
                //console.log(res)
                this.tiposAsiento = res.data.detail.tipos_asiento;
                this.cuentas = res.data.detail.cuentas
                this.overlay = false
            }).catch(()=>{
                this.overlay = false
            });
        },

        cargarAsiento(id) {
            this.overlay = true
            this.setUrl('acctg-transaction/'+id)
            this.requestApi({
                method: 'GET',
                data: {
                                  
                }
            }).then(res => {                
                //console.log(res)
                this.datosAsiento = res.data.detail.acctg_trans;
                this.datosAsiento.transaction_date = moment(res.data.detail.acctg_trans.transaction_date).format("YYYY-MM-DD")
                if(this.datosAsiento.is_posted=='Y') {
                    this.lectura = true
                }
                this.listaAte = res.data.detail.acctg_trans_entries;
                this.overlay = false
            }).catch(()=>{
                this.overlay = false
            });
        },

        editarAsientoForm(id) {
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id: id,
                    accion: "editarAsientoForm"     
                }
            }).then(res => {                
                
                this.datosAsiento = res.data.detail.datosAsiento.acctg_trans;
                this.datosAsiento.transaction_date = moment(res.data.detail.datosAsiento.acctg_trans.transaction_date).format("YYYY-MM-DD")
                if(this.datosAsiento.is_posted=='Y') {
                    this.lectura = true
                }
                this.listaAte = res.data.detail.datosAsiento.acctg_trans_entries;
                this.cuentas = res.data.detail.datosForm.cuentas
                this.overlay=false
            }).catch(()=>{
                this.overlay=false
            });
        },
                
        
        handlePageChange(value) {
            this.currentPage = value
            
        },

        guardarAsiento() {
            this.overlay = true
            this.setUrl('acctg-transaction')
            this.requestApi({
                method: 'POST',
                data: {
                    accion: this.datosAsiento.acctg_trans_id == undefined ? "crearAsiento" : "modificarAsiento",
                    datosAsiento: this.datosAsiento
                                  
                }
            }).then(res => {      
                this.overlay = false
                this.cargarAsiento(this.datosAsiento.acctg_trans_id)          
                //console.log(res)
                //this.datosAsiento = res.data.detail.acctg_trans;
                //this.listaAte = res.data.detail.acctg_trans_entries;
            }).catch(()=>{
                this.overlay = false
            });
        },

     
        procesarAsiento() {
            this.overlay = true
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    acctg_trans_id : this.$route.params.acctgTransId,
                    accion: "procesarAsiento"       
                }
            }).then(res => {   
                //this.cargarAsiento(this.$route.params.acctgTransId)
                this.overlay = false             
                this.$router.push("/asientoContableVista/"+this.$route.params.acctgTransId)
                //console.log(res)
                //this.cargarAsiento(this.$route.params.acctgTransId)
                //this.datosAsiento = res.data.detail.acctg_trans;
                //this.listaAte = res.data.detail.acctg_trans_entries;

            }).catch(()=>{
                this.overlay = false
            });
        },

        borrarApunte(item) {

            Vue.swal({
                html: "Está seguro de eliminar este apunte contable ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                
                if (result.isConfirmed) {

                    this.setUrl('acctg-transaction')

                    this.requestApi({
                        method: 'POST',
                        data: {
                            accion: "borrarItemAsiento",
                            acctg_trans_id: item.acctg_trans_id,
                            acctg_trans_entry_seq_id: item.acctg_trans_entry_seq_id
                                        
                        }
                    }).then(res => {                
                        //console.log(res)
                        this.cargarAsiento(this.$route.params.acctgTransId)
                        //this.datosAsiento = res.data.detail.acctg_trans;
                        //this.listaAte = res.data.detail.acctg_trans_entries;
                    });

                }

            })


            
        },
        nuevoApunte() {
            this.datosApunte = {acctg_trans_id:this.datosAsiento.acctg_trans_id, acctg_trans_entry_seq_id:""}
            this.dialogoApunte=true
        },
        editarApunte(item) {
            this.dialogoApunte = true
            this.datosApunte = item
        },
        guardarApunte() {
            if(!this.$refs.formApunte.validate()){
                return false
            }
            if(this.datosApunte.acctg_trans_entry_seq_id == "") {
                this.crearApunte(this.datosApunte)
            } else {
                this.modificarApunte(this.datosApunte)
            }

        },
        modificarApunte() {
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    accion: "modificarItemAsiento",
                    datosApunte: this.datosApunte
                                
                }
            }).then(res => {                
                //console.log(res)
                //this.datosAsiento = res.data.detail.acctg_trans;
                //this.listaAte = res.data.detail.acctg_trans_entries;
                this.dialogoApunte = false
            });

        },

        crearApunte() {
            this.setUrl('acctg-transaction')

            this.requestApi({
                method: 'POST',
                data: {
                    accion: "crearItemAsiento",
                    datosApunte: this.datosApunte
                                
                }
            }).then(res => {                
                //console.log(res)
                //this.datosAsiento = res.data.detail.acctg_trans;
                //this.listaAte = res.data.detail.acctg_trans_entries;
                this.dialogoApunte = false
                this.cargarAsiento(this.$route.params.acctgTransId)
            });

        }


    },

    watch: {

        options: {
            handler() {
                //this.cargarAsiento()
            },
            deep: true,
        },
    },
    mounted() {        
        this.datosAsiento = {transaction_date: moment(new Date()).format('YYYY-MM-DD'), gl_fiscal_type_id:"ACTUAL" }
        this.cargarDatos();
        if(this.$route.params.acctgTransId != undefined) {
            this.cargarAsiento(this.$route.params.acctgTransId)
        }

    },



}
</script>