<template>
    <div>
        <v-overlay :value="overlay" z-index="999999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-1"
            :loading=loadingTable
            :search=search
            item-key="horario_laboral_id"
            loading-text="Buscando horarios..."
            dense
        >
            <template v-slot:top>
                <v-row align="center" justify="center">
                    <v-col cols=12 md=3 class='text-center'>
                        <v-toolbar-title>
                            <v-icon>mdi-calendar-clock</v-icon> HORARIOS DE TRABAJO
                        </v-toolbar-title>
                    </v-col>
                    <v-col md=6>
                        <v-text-field v-model=search label=Buscar />
                    </v-col>
                    <v-col 
                        md=3 
                        class='text-center' 
                    >
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="success"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                >
                                    <v-icon>mdi-plus</v-icon> Nueva configuración
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            text
                                            small
                                            block
                                            class="w-100"
                                            @click="openDialogHorario"
                                        >
                                            <v-icon>mdi-calendar-clock</v-icon> Configuración de horario
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            text
                                            small
                                            block
                                            class="w-100"
                                            @click="openDialogFeriados"
                                        >
                                            <v-icon>mdi-calendar-range</v-icon> Configuración de días feriados
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-results="{ item }">
                No se encontraron horarios de trabajo
            </template>
            <template v-slot:no-data>
                Sin registros
            </template>
            <template v-slot:item.actions="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            color="warning" 
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editConfiguracionHorario(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar configuración</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            color="error" 
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteConfiguracionHorario(item)"
                        >mdi-delete</v-icon>
                    </template>
                    <span>Eliminar configuración</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog
            fullscreen
            v-model=dialogHorario
            :persistent=true
        >
            <v-toolbar dark color="primary" dense>
                <v-btn icon dark @click='close'>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-col cols="12" md=8 sm=12 class="caption text-md-h6">
                    <v-icon>mdi-calendar-clock</v-icon> CONFIGURACIÓN DE HORARIOS DE TRABAJO
                </v-col>
            </v-toolbar>                        
            <v-card>
                <v-col cols=12 class="py-0">
                    <v-form ref="form_horario">
                        <v-col cols="12">
                            <v-row>
                                <v-col md="4" class="pt-6">
                                    <v-card>
                                        <v-toolbar dark color="primary" style="height: 48px;">
                                            <div dense class="check-dias">
                                                <div style="position: relative;bottom: 8px;">
                                                    CONFIGURACIÓN BÁSICA
                                                </div>
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            style="position: relative;bottom: 8px;"
                                                            color="blue-grey lighten-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            fab
                                                            @click="addConfiguracionHoraria"
                                                            x-small
                                                            :disabled="horario.excepcion"
                                                        >
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Agregar configuración horaria</span>
                                                </v-tooltip>
                                            </div>
                                        </v-toolbar>   
                                        <v-col cols="12" class="pt-5 pb-0">
                                            <label ><b> Nombre del horario</b></label>
                                            <v-text-field
                                                v-model=horario.nombre
                                                dense
                                                outlined
                                                class="text-center pb-2"
                                                :rules=required
                                                hide-details="auto"
                                                prepend-inner-icon="mdi-pencil"
                                            />
                                        </v-col>
                                        <v-col cols="12" class="my-0 py-3">
                                            <label > <b>Tienda </b></label>
                                            <v-autocomplete
                                                v-model='horario.product_store_id'
                                                :items=productStores
                                                dense
                                                outlined
                                                item-value="product_store_id"
                                                item-text="store_name"
                                                :rules="!horario.replicar_tiendas ? required : []"
                                                hide-details="auto"
                                                prepend-inner-icon="mdi-store"
                                            />
                                            <v-checkbox  
                                                v-model="horario.replicar_tiendas"
                                                v-if="horario.horario_trabajo_id == null"
                                            >
                                                <template v-slot:label>
                                                    <b>Crear en todas las tiendas</b>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="12" class="my-0 py-0">
                                            <label > <b>Zona horaria </b></label>
                                            <v-autocomplete
                                                v-model='horario.zona_horaria'
                                                :items=zonasHorarias
                                                dense
                                                outlined
                                                item-value="zona_horaria_id"
                                                item-text="zona"
                                                :rules=required
                                                hide-details="auto"
                                                prepend-inner-icon="mdi-calendar-clock"
                                            />
                                        </v-col>
                                        <v-col cols="12" class="my-0 py-0">
                                            <v-checkbox
                                                v-model="horario.default"
                                                :disabled="horario.excepcion"
                                            >
                                                <template v-slot:label>
                                                    <b>Horario por defecto</b>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="12" class="my-0 py-0">
                                            <v-checkbox
                                                v-model="horario.excepcion"
                                                @change="horario.default = !horario.excepcion"
                                            >
                                                <template v-slot:label>
                                                    <b>Horario de excepción</b>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="12" >
                                            <v-btn
                                                color="primary"
                                                block
                                                @click="storeConfiguracionHorarioTrabajo"
                                            >
                                                GUARDAR
                                            </v-btn>
                                        </v-col>
                                    </v-card>
                                </v-col>
                                <v-col md="8">
                                    <div v-if="horario.excepcion">
                                        <v-col
                                            v-for="(h, dia) in horario.dias"
                                            :key="dia"
                                        >
                                            <v-toolbar dark color="primary" dense>
                                                <div class="check-dias">
                                                <div>
                                                    CONFIGURACIÓN HORARIA
                                                </div>
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                        
                                                            color="red"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            fab
                                                            @click="removeConfiguracionHoraria(h)"
                                                            x-small
                                                        >
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar configuración horaria</span>
                                                </v-tooltip>
                                            </div>
                                            </v-toolbar> 
                                            <v-card class="px-3 py-3">
                                                <div>
                                                <label ><b> <v-icon>mdi-calendar</v-icon> Fecha de la excepción</b></label> 
                                            </div>
                                            <input 
                                                class="input-excepcion"
                                                type="date" 
                                                v-model="horario.fecha_excepcion" 
                                            />
                                                <div class="mt-4">
                                                    <label><b> <v-icon>mdi-clock</v-icon> Horario de la excepción</b></label>
                                                    <v-row class="mt-2">
                                                        <v-col cols="12" md="6">
                                                            <label class="mr-5">DESDE:</label>
                                                            <input 
                                                                type="time" 
                                                                name="desde" 
                                                                v-model="h.hora_desde" 
                                                                :rules="required"
                                                            />
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <label class="mr-5">HASTA:</label>
                                                            <input 
                                                                type="time" 
                                                                name="hasta" 
                                                                v-model="h.hora_hasta" 
                                                                :rules="required"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </div>
                                    <div v-else>
                                        <v-col
                                            v-for="(h, dia) in horario.dias"
                                            :key="dia"
                                        >
                                            <v-toolbar dark color="primary" dense>
                                                <div class="check-dias">
                                                <div>
                                                    CONFIGURACIÓN HORARIA
                                                </div>
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                        
                                                            color="red"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            fab
                                                            @click="removeConfiguracionHoraria(h)"
                                                            x-small
                                                        >
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar configuración horaria</span>
                                                </v-tooltip>
                                            </div>
                                            </v-toolbar> 
                                            <v-card class="px-3 py-3">
                                                <label ><b> <v-icon>mdi-calendar-multiple</v-icon> Dias de la semana</b></label>
                                                <div class="check-dias">
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.LU"
                                                        label="LUNES"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.MA"
                                                        label="MARTES"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.MI"
                                                        label="MIERCOLES"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.JU"
                                                        label="JUEVES"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.VI"
                                                        label="VIERNES"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.SA"
                                                        label="SÁBADO"
                                                        hide-details="auto"
                                                    />
                                                    <v-checkbox
                                                        class=""
                                                        v-model="h.semana.DO"
                                                        label="DOMINGO"
                                                        hide-details="auto"
                                                    />
                                                </div>  
                                                <div class="mt-4">
                                                    <label><b> <v-icon>mdi-clock</v-icon> Horarios</b></label>
                                                    <v-row class="mt-2">
                                                        <v-col cols="12" md="6">
                                                            <label class="mr-5">DESDE:</label>
                                                            <input 
                                                                type="time" 
                                                                name="desde" 
                                                                v-model="h.hora_desde" 
                                                                :rules="required"
                                                            />
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <label class="mr-5">HASTA:</label>
                                                            <input 
                                                                type="time" 
                                                                name="hasta" 
                                                                v-model="h.hora_hasta" 
                                                                :rules="required"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog
            width="700px"
            v-model=dialogFeriados
            :persistent=true
        >
            <v-toolbar dark color="primary" dense>
               
                <div dense class="check-dias">
                    <div>
                        <v-icon>mdi-calendar-range</v-icon> CONFIGURACIÓN DE FERIADOS
                    </div>
                    <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="blue-grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                fab
                                @click="addDiaFeriado"
                                x-small
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Agregar día</span>
                    </v-tooltip>
                </div>
            </v-toolbar>                        
            <v-card>
                <v-col cols=12 class="py-0">
                    <v-form ref="form_horario">
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="px-3 py-3">
                                        <v-data-table
                                            :headers="headersFechaFeriado"
                                            :items="dataTableFeriados"
                                            class="elevation-1"
                                            :loading=loadingTable
                                            :search=searchFeriado
                                            item-key="fecha_feriado_id"
                                            loading-text="Buscando feriados..."
                                            dense
                                        >
                                            <template v-slot:item.action="{item}">                                            
                                                <v-icon 
                                                    @click="deleteFechaFeriado(item)"
                                                    color="error"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.fecha="props">
                                                <v-edit-dialog
                                                    :return-value.sync="props.item.fecha"
                                                    large
                                                    persistent
                                                    save-text="Guardar"
                                                    cancel-text="Cancelar"
                                                    @save="storeFeriado(props)"
                                                >
                                                <div>{{ props.item.fecha }}</div>
                                                <template v-slot:input>
                                                    <div class="mt-4 text-h6"> Fecha </div>
                                                    <input  
                                                        v-model= props.item.fecha
                                                        type="date"
                                                    />
                                                </template>
                                                </v-edit-dialog>
                                            </template>
                                        </v-data-table>
                                        <v-card-actions>
                                            <v-col class='text-center'>
                                                <v-btn  @click="close">
                                                    <v-icon>mdi-cancel</v-icon> Cancelar
                                                </v-btn>
                                            </v-col>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import Vue from 'vue'

var textFechaferiado = 'Clic aquí para ingresar fecha'

export default {
    name:'HorarioTrabajoComponent',
    data: () => ({
        headers: [
            { text: 'Nombre', value: 'nombre' },
            { text: 'Zona horaria', value: 'zona' },
            { text: 'Predeterminado', value: 'predeterminada' },
            { text: 'Excepción', value: 'excepcion' },
            { text: 'Fecha excepción', value: 'fecha_excepcion' },
            { text: 'Empresa', value: 'store_name' },
            { text: 'Creado', value: 'created_stamp' },
            { text: 'Acciones', value: 'actions', sortable: false, width:'120px',align:'center' }
        ],
        headersFechaFeriado: [
            { text: 'ID', value: 'fecha_feriado_id' },
            { text: 'Fecha', value: 'fecha' },
            { text: 'Acción', value: 'action' }
        ],
        required: [v => !!v || 'El campo es requerido'],
        overlay:false,
        dialogHorario: false,
        dialogFeriados: false,
        dataTable:[],
        zonasHorarias:[],
        productStores:[],
        dataTableFeriados:[],
        search:null,
        searchFeriado:null,
        horario: {
            product_store_id:null,
            horario_trabajo_id:null,
            nombre: null,
            zona_horaria:null,
            default:false,
            replicar_tiendas:false,
            excepcion:false,
            dias:[{
                product_store_id:null,
                uuid: Math.random().toString(36).substr(0, 20),
                hora_desde: '',
                hora_hasta: '',
                semana:{
                    'LU': false,
                    'MA': false,
                    'MI': false,
                    'JU': false,
                    'VI': false,
                    'SA': false,
                    'DO': false
                }
            }]
        },
        defaultHorario: {
            product_store_id:null,
            horario_trabajo_id:null,
            nombre: null,
            zona_horaria:null,
            replicar_tiendas:false,
            default:false,
            excepcion:false,
            dias:[{
                product_store_id:null,
                uuid: Math.random().toString(36).substr(0, 20),
                hora_desde: '',
                hora_hasta: '',
                semana:{
                    'LU': false,
                    'MA': false,
                    'MI': false,
                    'JU': false,
                    'VI': false,
                    'SA': false,
                    'DO': false
                }
            }]
        }
    }),
    computed:{
        
        ...mapState('master',['loadingTable','tenantId','paramAlertQuestion']),

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        getConfiguracionHorario(){

            this.setUrl('horario_trabajo')
            this.overlay = true 

            this.requestApi({
                method: 'GET',
                data :{
                    tenantId: this.tenantId,
                    typeList: 'getConfiguracionHorario'
                }
            }).then(res =>{
    
                this.dataTable = res.data._embedded.horario_trabajo
                this.overlay = false

            }).catch(()=>{
                
                this.overlay=false
                

            })

        },

        openDialogHorario(){

            return new Promise((resolve, reject) =>{

                this.setUrl('horario_trabajo')
                this.overlay = true 

                this.requestApi({
                    method: 'GET',
                    data :{
                        tenantId: this.tenantId,
                        typeList: 'zonaHoraria'
                    }
                })
                .then(res =>{

                    this.dialogHorario =true
                    this.overlay=false
                    this.zonasHorarias = res.data._embedded.horario_trabajo[0]
                    this.productStores = res.data._embedded.horario_trabajo[1]
                    resolve()
                    
                })
                .catch(()=>{
                    
                    this.overlay=false
                    reject()
                })  

            })

        },

        openDialogFeriados(){
          
            return new Promise((resolve, reject) =>{

                this.setUrl('horario_trabajo')
                this.overlay = true 

                this.requestApi({
                    method: 'GET',
                    data :{
                        tenantId: this.tenantId,
                        typeList: 'feriados'
                    }
                }).then(res =>{
        
                    res.data._embedded.horario_trabajo.map(obj => {

                        obj.uuid = Math.random().toString(36).substr(0, 20)
                        return obj

                    })

                    this.overlay=false
                    this.dialogFeriados =true
                    this.dataTableFeriados = res.data._embedded.horario_trabajo
                    resolve()

                }).catch(()=>{
                    
                    this.overlay=false
                    reject()

                })  

            })

        },

        close(){

            this.dialogHorario = false
            this.dialogFeriados = false
            Object.assign(this.horario, this.defaultHorario)

        },

        addConfiguracionHoraria(){

            this.horario.dias.push({
                product_store_id:null,
                uuid: Math.random().toString(36).substr(0, 20),
                hora_desde:null,
                hora_hasta: null,
                horario_trabajo_id:null,
                replicar_tiendas:false,
                semana:{
                    'LU': false,
                    'MA': false,
                    'MI': false,
                    'JU': false,
                    'VI': false,
                    'SA': false,
                    'DO': false
                }
            })

        },

        addDiaFeriado(){

            this.dataTableFeriados.unshift({
                uuid: Math.random().toString(36).substr(0, 20),
                fecha_feriado_id : null,
                fecha: textFechaferiado
            })

        },

        removeConfiguracionHoraria(item){

            let obj = this.horario.dias.find(e => e.uuid == item.uuid)
            let index = this.horario.dias.indexOf(obj)
            this.horario.dias.splice(index,1)

        },

        storeFeriado(props){

            this.setUrl('horario_trabajo')
            this.overlay=true
            const { fecha_feriado_id, fecha } = props.item

            this.requestApi({
                method: 'POST',
                data :{
                    fecha,
                    tenantId: this.tenantId,
                    fechaFeridoId: fecha_feriado_id,
                    typeAction: 'storeFechaFeriado'
                }
            }).then(async res =>{
      
                let news = this.dataTableFeriados.filter(e => e.fecha == textFechaferiado)
                await this.openDialogFeriados()

                news.forEach(e =>{ this.dataTableFeriados.unshift(e) })

                this.overlay=false
                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

            }).catch(()=>{
                
                this.overlay=false
            })  

        },

        removeDiaFeriado(item){

            let obj = this.horario.dias.find(e => e.uuid == item.uuid)
            let index = this.horario.dias.indexOf(obj)
            this.horario.dias.splice(index,1)

        },

        deleteFechaFeriado(item){

            if(item.fecha_feriado_id != null){

                Vue.swal({
                    html: "Esta seguro de eliminar la fecha del feriado?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion,
                }).then((result) => {

                    if (result.isConfirmed) {

                        this.setUrl('horario_trabajo')
                        this.overlay=true

                        this.requestApi({
                            method: 'DELETE',
                            data :{
                                tenantId: this.tenantId,
                                fechaFeridoId: item.fecha_feriado_id,
                                typeDelete: 'fechaFeriado'
                            }
                        }).then(res =>{
                            console.log(res)

                            this.overlay=false
                            this.alertNotification({
                                param:{
                                    html: res.data.detail
                                }
                            })

                            let obj = this.dataTableFeriados.find(e => e.uuid == item.uuid)
                            let index = this.dataTableFeriados.indexOf(obj)
                            this.dataTableFeriados.splice(index,1)

                        }).catch(()=>{
                            
                            this.overlay=false
                        })  

                    }

                })

            }else{

                let obj = this.dataTableFeriados.find(e => e.uuid == item.uuid)
                let index = this.dataTableFeriados.indexOf(obj)
                this.dataTableFeriados.splice(index,1)

            }
           
        },

        storeConfiguracionHorarioTrabajo(){

            this.setUrl('horario_trabajo')
            this.overlay=true
        
            this.requestApi({
                method: 'POST',
                data :{
                    ...this.horario,
                    tenantId: this.tenantId,
                    typeAction: 'storeConfiguracionHorarioTrabajo'
                }
            }).then(res =>{
      
                this.getConfiguracionHorario()
                this.close()
                this.overlay=false

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

            }).catch(()=>{
                
                this.overlay=false
            })  

        },

        editConfiguracionHorario(item){
           
            this.setUrl('horario_trabajo')
            this.overlay = true 

            this.requestApi({
                method: 'GET',
                data :{
                    tenantId: this.tenantId,
                    horarioTrabajoId: item.horario_trabajo_id,
                    typeList: 'getHorarioTrabajoId'
                }
            }).then(res =>{                 
                
                const { 
                    horario_trabajo_id,
                    fecha_excepcion,
                    nombre,
                    predeterminada,
                    product_store_id,
                    zona_horaria,
                    excepcion,
                    detalles
                } = res.data._embedded.horario_trabajo[0]

                this.horario.uuid = Math.random().toString(36).substr(0, 20),
                this.horario.horario_trabajo_id = horario_trabajo_id
                this.horario.excepcion = excepcion== 'SI'
                this.horario.fecha_excepcion = fecha_excepcion 
                this.horario.nombre = nombre
                this.horario.default = predeterminada == 'SI'
                this.horario.product_store_id = product_store_id
                this.horario.zona_horaria = zona_horaria

                this.horario.dias = []

                detalles.forEach(obj => {

                    this.horario.dias.push({
                        hora_desde: obj[0].desde,
                        hora_hasta: obj[0].hasta,
                        semana:{
                            'LU': obj.some(e => e.dia  == 'LU') ,
                            'MA':  obj.some(e => e.dia  == 'MA'),
                            'MI':  obj.some(e => e.dia  == 'MI'),
                            'JU':  obj.some(e => e.dia  == 'JU'),
                            'VI':  obj.some(e => e.dia  == 'VI'),
                            'SA':  obj.some(e => e.dia  == 'SA'),
                            'DO':  obj.some(e => e.dia  == 'DO')
                        }
                    })

                })

                this.openDialogHorario()

                this.overlay = false

            }).catch(()=>{
                
                this.overlay=false
               
            })  

         

        },

        deleteConfiguracionHorario(item){
    
            Vue.swal({  
                html: "Esta seguro de eliminar la configuración del horario?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl('horario_trabajo')
                    this.overlay=true

                    this.requestApi({
                        method: 'DELETE',
                        data :{
                            tenantId: this.tenantId,
                            horarioTrabajoId: item.horario_trabajo_id,
                            typeDelete: 'horarioTrabajo'
                        }
                    }).then(res =>{
                        console.log(res)

                        this.overlay=false
                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })

                        let obj = this.dataTable.find(e => e.horario_trabajo_id == item.horario_trabajo_id)
                        let index = this.dataTable.indexOf(obj)
                        this.dataTable.splice(index,1)

                    }).catch(()=>{
                        
                        this.overlay=false
                    })  

                }

            })

        }

    },
    created(){

        this.setTitleToolbar()
        this.getConfiguracionHorario()

    }

}

</script>

<style>

    .check-dias{
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

    }

    .input-excepcion{
        border: 1px solid #9e9e9e;
        border-radius: 5px;
        width: 50%;
        padding: 5px;
        margin-top: 10px;
    }

    @media(max-width: 960px){

        .input-excepcion{
            width: 100%;
        }

    }
</style>